<template>
  <el-row class="container">
    <!--头部-->
    <el-col style="background-color:#545c64" :span="24" :class="!$route.meta.NoNeedHome ? 'topbar-wrap' : ''">
      <transition v-if="!$route.meta.NoNeedHome" name="fade" mode="out-in">
        <el-row class="container">
          <el-col :span="24">
            <div class="topbar-logo topbar-btn">
              <a><img src="./assets/logo.png" style="padding-left: 20px;margin-top:9px;" /></a>
            </div>
            <div class="topbar-logos">
              <a style="color: #fff"><span style="cursor:default">{{ collapsed ? sysNameShort : sysName }}</span></a>
            </div>
            <div class="topbar-title">
              <!-- 注意：这里就是topNavState作用之处，根据当前路由所在根路由的type值判断显示不同顶部导航菜单 -->
              <el-row>
                <el-col :span="24">
                  <el-menu
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelectMenu"
                    :default-active="defaultActiveIndex"
                  background-color="#545c64"
                  text-color="#fff"
                  active-text-color="#ffd04b"
                  >
                    <el-menu-item
                      v-for="(term, index) in menus"
                      :key="index"
                      :index="index.toString()"
                      >{{ term.name }}</el-menu-item
                    >
                  </el-menu>
                </el-col>
              </el-row>
            </div>
            <div class="topbar-account topbar-btn" style="line-height: 48px;
    font-size: 14px;">
                <el-row>
                <el-col :span="24">
                  <el-popover
                    placement="bottom"
                    width="800"
                    height="500"
                    title="小日历"
                    trigger="click">
                    <el-calendar>
                      <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                      <template
                        slot="dateCell"
                        slot-scope="{date, data}">
                        <p :class="data.isSelected ? 'is-selected' : ''">
                          {{ getday(data.day) }}
                           <!-- {{ data.isSelected ? '✔️' : ''}} -->
                        </p>
                        <!-- <p :class="data.isSelected ? 'is-selected' : ''">
                          {{ getday(data.day) }}
                        </p>
                        <div v-bind:key="index" v-for="(item,index) in riliData">
                          <span>{{item.a}}</span>
                        </div> -->

                        <!-- <p style="font-size:9px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">
                          <el-link :underline="false" style="font-size:9px;">1、工作任务系统数据库、接口性能优化</el-link>
                        </p>
                        <p style="font-size:9px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">
                          <el-link :underline="false" style="font-size:9px;">2、工作任务系统数据库、接口性能优化</el-link>
                        </p> -->
                        <!-- <p style="font-size:9px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis">
                          <el-link @click="handleLookTask" :underline="false" style="font-size:9px;">3、工作任务系统数据库、接口性能优化</el-link>
                        </p> -->
                        <!-- <el-link :underline="false" style="font-size:9px;">更多</el-link> -->
                      </template>
                    </el-calendar>
                    <el-button type="text" style="color:white" slot="reference">{{ getnow() }}<i class="el-icon-date"></i></el-button>
                  </el-popover>
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link userinfo-inner">
                      {{ sysUserName }}
                      <img
                        :src="imageUrl"
                        style="margin-top: 0px"
                        height="128"
                        width="128"
                      />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <!-- <el-dropdown-item @click.native="myNews">
                      <el-badge :value="messcount" :hidden="badgehidden" class="item" type="warning">我的消息</el-badge>
                    </el-dropdown-item> -->
                      <!-- <el-dropdown-item @click.native="Setting"
                        >修改密码</el-dropdown-item
                      > -->
                      <!-- <el-dropdown-item divided @click.native="logout" style="font-weight: bold;">退出登录</el-dropdown-item> -->
                      <el-dropdown-item
                        @click.native="logout"
                        style="font-weight: bold"
                        >退出登录</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
           
            </div>
          </el-col>

          <el-col :span="24" class="main">
            <!--左侧导航-->
            <aside :class="collapsedClass" v-if="isCollapsdMenu">
             
              <el-scrollbar
                style="height: 100%; border-right:1px solid #f0f0f0"
                class="scrollbar-handle" 
              >
               <div style="background-color:white;" class="menu-toggle" @click.prevent="collapse">
                
                <el-tooltip content="点击收起菜单" placement="left" :enterable="false" effect="light">
                  <i class="iconfont icon-outdent" style="color:#303133"
                    v-show="!collapsed"  ></i>
                </el-tooltip>
                <el-tooltip content="点击展开菜单" placement="right" :enterable="false" effect="light">
                  <i class="iconfont icon-indent" style="color:#909399"
                    v-show="collapsed" ></i>
                </el-tooltip>
              </div>
                <el-menu
                  :default-openeds="openeds"
                  :default-active="$route.path"
                  class="el-menu-vertical-demo"
                  @open="handleopen"
                  @close="handleclose"
                  @select="handleselect"
                  unique-opened
                  router
                  :collapse="isCollapse"
                  background-color="#fff"
                  text-color="#303133"
                  active-text-color="#409eff"
                >
                  <sidebar
                    v-for="(menu, index) in routes"
                    :key="index"
                    :item="menu"
                  />
                </el-menu>
              </el-scrollbar>
            </aside>
            <!--右侧内容区-->
           
            <section class="content-container">
               <Loading v-show="IsLoading"></Loading>
              <div class="grid-content bg-purple-light">
                <transition name="fade" mode="out-in">
                  <router-view :key="$route.query.t"></router-view>
                </transition>
              </div>
            </section>
          </el-col>
        </el-row>
      </transition>

      <transition v-else name="fade" mode="out-in">
        <div class>
          <router-view></router-view>
        </div>
      </transition>
      <el-dialog
        title="Unread Messages"
        :class="newsDialogCss"
        :visible.sync="NewsVisible"
        v-model="NewsVisible"
        :close-on-click-modal="false"
      >
        <div>
          <el-tag
            v-for="tag in tagNews"
            :key="tag.name"
            closable
            class="tag-new"
            :type="tag.type"
            >{{ tag.name }}</el-tag
          >
        </div>
      </el-dialog>

      <div
        class="v-modal"
        @click="closeZModalShadow"
        v-show="NewsVisible"
        tabindex="0"
        style="z-index: 2917"
      ></div>

      <el-dialog
        title="设置密码"
        :visible.sync="passwordFormVisible"
        v-model="passwordFormVisible"
        :close-on-click-modal="false"
      >
        <el-form :model="passwordForm" label-width="80px" ref="passwordForm">
          <el-form-item
            label="原密码"
            prop="originPassword"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
          >
            <el-input
              type="password"
              v-model="passwordForm.originPassword"
              auto-complete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="新密码"
            prop="newPassword"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
          >
            <el-input
              type="password"
              v-model="passwordForm.newPassword"
              auto-complete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="确认密码"
            prop="confimPassword"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
          >
            <el-input
              type="password"
              v-model="passwordForm.confimPassword"
              auto-complete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="passwordFormVisible = false"
            >取消</el-button
          >
          <el-button type="primary" @click="passwordSubmit">提交</el-button>
        </div>
      </el-dialog>
    </el-col>
  </el-row>
</template>
<script>
import Sidebar from "./components/Sidebar";
import ScrollPane from "./components/ScrollPane";
import Loading from "./components/Loading";
import {
  UserModifytPassword,
  GetNewMenuNavigationBar,
  getNavigationBar,
  GetNeButtonList,
} from "./api/public";

import router from "@/router";
import { resetRouter, filterAsyncRouter } from "@/router/index";
import { GetRoleUserList } from './api/hr';
import {GetUserPostList,RefreshToken} from './api/oa';
import util from '../util/date';
import Qs from "qs";
export default {
  components: { Sidebar, ScrollPane,Loading },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      metaInfo:{
        meta:[
          { name:'google', content:'notranslate' }
        ]
      },
      openeds: [],
      isCollapsdMenu: false,
      sysName: "工作任务系统",
      sysNameShort: "工作任务系统",
      NewsVisible: false,
      passwordFormVisible: false,
      SidebarVisible: false,
      collapsed: false,
      clientid: null,
      msgcontent: null,       //消息内容
      msgshowtime: null,
      messcount: 0,
      zModalShadow: false,
      collapsedClass: "menu-expanded",
      ispc: false,
      sysUserName: "",
      newsDialogCss: "news-dialog",
      sysUserAvatar: "",
      isCollapse: false,
      defaultActiveIndex: "0",
      // isRouterAlive: false,
      tagsList: [],
      passwordForm: {
        id: "",
        originPassword: null,
        newPassword: null,
        confimPassword: null,
      },
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      menus: [],
      routes: [],
      tagNews: [],
      riliData: [],
      visible: false,
      top: 0,
      left: 0,
      imageUrl: require("./assets/shezhi.png"),
      selectedTag: {},
      affixTags: [],
      page:''
    };
  },
  methods: {
    handleLookTask()
    {
      alert();
    },
    getday(d)
    {
      return util.formatDate.format(new Date(d),"d日");
    },
    getnow()
    {
      return util.formatDate.format(new Date(),"yyyy年M月d日");
    },
    handleSelectMenu(key) {
      let self=this;
      if (this.$route.meta.NoNeedHome && !this.$route.meta.requireAuth) {
        this.$router.replace(this.$route.path);
        return;
      } else {
        this.defaultActiveIndex = key;
        window.localStorage.menuKey = key;
        // console.log(this.menus);
        let type = this.menus[key].type;
        let user = JSON.parse(window.localStorage.user);
        if(!user)
        {
           this.$router.push('/login');
        }

        console.log(type)
        switch (type) {
          case 0: //单页面应用
            this.isCollapsdMenu = false;
            let menu = JSON.parse(window.localStorage.menu);

            let getRouter = filterAsyncRouter(menu); //过滤路由
            router.$addRoutes(getRouter); //动态添加路由


            // let self=this;
            self.page = window.localStorage.getItem("page");
            //console.log(self.page)
            if(self.page==undefined)
            {
              this.$router.replace(this.menus[key].path).catch((data) => {});
            }else{
              if(self.page=='' || self.page=='undefined')
              {
                this.$router.replace(this.menus[key].path).catch((data) => {});
              }
              else if(self.page=='waitdo')
              {

                // console.log(this.menus);
                this.getNeButtonList(user.sub, this.menus[1].id);
                // this.$router.replace(this.menus[1].path).catch((data) => {});
                // this.$router.replace(this.menus[key].path).catch((data) => {});
                setTimeout(() => {
                  this.$router.replace({
                    path: "/Task/WaitDoTask",
                  });
                  window.localStorage.removeItem("page");
                }, 1000);
              }
              else if(self.page=='waitread')
              {
                this.getNeButtonList(user.sub, this.menus[1].id);
                setTimeout(() => {
                  this.$router.replace({
                    path: "/Task/WaitReadTask",
                  });
                  window.localStorage.removeItem("page");
                }, 1000);
              }
              else if(self.page=='waitapproval')
              {
                this.getNeButtonList(user.sub, this.menus[1].id);
                setTimeout(() => {
                  this.$router.replace({
                    path: "/Task/WaitApproval",
                  });
                  window.localStorage.removeItem("page");
                }, 1000);
              }
            }

            // this.$router.replace(this.menus[key].path).catch((data) => {});
            break;

          case 1: //带导航应用
            this.isCollapsdMenu = true;

            this.getNeButtonList(user.sub, this.menus[key].id);

            break;
          case 2: //外部应用
            this.isCollapsdMenu = false;
            this.$router.push({
              name: "userTemplate",
              query: {
                reportUrl: this.menus[key].path,
                reportType: 0,
                t: Date.now(),
              },
            });
            break;
        }
      }
    },
    //消息弹框显示
  MessageTip() {
      this.$notify({
        title: '消息提醒',
        message: this.msgcontent,
        duration: this.msgshowtime,
        type: "success",
        customClass: "msgclass",
        position: 'bottom-right'
      });
  },
    //获取按钮
    async getNeButtonList(uid, pid) {
      var _this = this;
      var loginParams = { uid: uid, mid: pid };

      const params = {};
      params.grant_type = 'refresh_token';
      params.client_id = 'client-grad-pwd';
      params.client_secret = 'T78JVybD7u2kBcss';
      params.refresh_token = window.localStorage.wtrefresh_token;
      await RefreshToken(Qs.stringify(params)).then((res)=>{
        // console.log(res)
        if(res.status==200)
        {
          _this.$store.commit("login", res.data);
        }
      })

      await GetNeButtonList(loginParams).then((data) => {
        // console.log(data);
        if (!data.success) {
          // _this.$message({
          //   message: data.message,
          //   type: "error",
          // });
          console.log('to refuse')
          this.$router.push("/refuse");
        } else {
          window.localStorage.buttList = JSON.stringify(data.response);
          this.GetNavigationBar(uid, pid);
        }
      });
      
      // await _this.loadDeptInfo();
    },
    GetNavigationBar(uid, pid) {
      var _this = this;
      var loginParams = { uid: uid, mid: pid };

      GetNewMenuNavigationBar(loginParams).then((data) => {
        // console.log(data);
        if (!data.success) {
          _this.$message({
            message: data.message,
            type: "error",
          });
          this.$router.push("/refuse");
        } else {
          let getRouter = data.response.children; //后台拿到路由

          window.localStorage.router = JSON.stringify(data.response.children);
          if (getRouter.length > 0) {
            this.isCollapsdMenu = true;
            this.routes = getRouter;

            getRouter = filterAsyncRouter(getRouter); //过滤路由
            router.$addRoutes(getRouter); //动态添加路由

            // if (
            //   getRouter[0]["children"] != null &&
            //   getRouter[0]["children"].length > 0
            // ) {
            //   let cPath = this.hasChilden(getRouter[0]["children"][0]);

            //   _this.$router.replace(cPath).catch((data) => {});
            // } else {
            //   _this.$router.replace(getRouter[0]["path"]).catch((data) => {});
            // }
            // this.defaultLeftNavOpened(getRouter);
          } else {
            this.isCollapsdMenu = false;
          }
        }
      });
    },
    hasChilden(res) {
      // console.log(res);
      if (res["children"] != null && res["children"].length > 0) {
        this.hasChilden(res["children"][0]);
      } else {
        // console.log(res);
        return res["path"];
      }
    },
    gotappath(Path) {
      // console.log("Path:" + Path);
      this.$router.replace({
        path: Path,
      });
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    closeZModalShadow() {
      this.NewsVisible = false;
    },
    toindex() {
      // this.$router.replace({
      //   path: "/",
      // });
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    onSubmit() {
      // console.log("submit!");
    },
    myNews() {
      this.$message({
        message: "功能开发中",
        type: "error",
      });
      return;
      // this.newsDialogCss += ' show ';
      // this.NewsVisible = true;
    },
    handleopen() {
      //console.log('handleopen');
      this.loadDeptInfo();
    },
    handleclose() {
      //console.log('handleclose');
    },
    handleselect: function (key, keyPath) {},
    //退出登录
    logout: function () {
      var _this = this;
      this.$confirm("确认退出吗?", "提示", {
        //type: 'warning'
      })
        .then(() => {
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("Token");
          window.localStorage.removeItem("TokenExpire");
          window.localStorage.removeItem("NavigationBar");
          window.localStorage.removeItem("refreshtime");
          window.localStorage.removeItem("router");
          window.localStorage.removeItem("menu");
          window.localStorage.removeItem("menuKey");
          window.localStorage.removeItem("buttList");
          window.localStorage.removeItem("deptId");
          window.localStorage.removeItem("deptName");
          sessionStorage.removeItem("Tags");

          global.antRouter = [];

          this.tagsList = [];
          this.routes = [];
          //this.$store.commit("saveTagsData", "");

           this.$router.push('/login');
          // window.location.reload()
        })
        .catch(() => {});
    },
    //设置
    Setting: function () {
      var user = JSON.parse(window.localStorage.user);
      if (user && user.sub != null) {
        this.passwordForm.id = user.sub;

        this.passwordFormVisible = true;
      } else {
        this.$message({
          message: "用户信息为空，请先登录",
          type: "error",
        });
        _this.$router.replace(
          _this.$route.query.redirect ? _this.$route.query.redirect : "/"
        );
      }
    },
    passwordSubmit() {
      this.$refs.passwordForm.validate((valid) => {
        if (
          this.passwordForm.confimPassword !== this.passwordForm.newPassword
        ) {
          this.$message({
            message: "两次密码不一致",
            type: "error",
          });
          return;
        }
        if (valid) {
          let para = Object.assign({}, this.passwordForm);
          UserModifytPassword(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.passwordFormVisible = false;
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        }
      });
    },
    //折叠导航栏
    collapse: function () {
      this.collapsed = !this.collapsed;

      if (this.ispc) {
        if (this.collapsed) {
          this.collapsedClass = "menu-collapsed";
        } else {
          this.collapsedClass = "menu-expanded";
        }
      } else {
        // mobile
        if (this.collapsed) {
          this.SidebarVisible = true;
          this.collapsedClass = "menu-collapsed-mobile";
        } else {
          this.SidebarVisible = false;
          this.collapsedClass = "menu-expanded-mobile";
        }

        this.collapsedClass += " mobile-ex ";
      }

      this.isCollapse = !this.isCollapse;
    },
    collapseFa: function () {
      if (!this.ispc) {
        this.collapse();
      }
    },
    defaultLeftNavOpened(rou) {
      let cur_path = this.$route.path; //获取当前路由
      // console.log(cur_path);
      let routers = rou; // 获取路由对象
      let subMenuIndex = [],
        needOpenSubmenu = false;

      for (let i = 0; i < routers.length; i++) {
        let children = routers[i].children;
        // console.log(children);
        if (children) {
          for (let j = 0; j < children.length; j++) {
            if (children[j].path === cur_path) {
              subMenuIndex.push(routers[i]["id"] + "index");
              needOpenSubmenu = true;
              break;
            }
            // console.log(children[j].children);
            // 如果该菜单下还有子菜单
            if (children[j].children) {
              let grandChildren = children[j].children;
              for (let z = 0; z < grandChildren.length; z++) {
                if (grandChildren[z].path === cur_path) {
                  subMenuIndex.push(children[j]["id"] + "index");
                  subMenuIndex.push(children[j]["pid"] + "index");

                  needOpenSubmenu = true;
                  break;
                }
              }
            }
          }
        }
      }

      this.openeds = subMenuIndex;
      // console.log(subMenuIndex);
    },
    showMenu(i, status) {
      this.$refs.menuCollapsed.getElementsByClassName(
        "submenu-hook-" + i
      )[0].style.display = status ? "block" : "none";
    },
    isActive(path) {
      return path === this.$route.fullPath;
    },
    loadDeptInfo(){
      // console.log(window.localStorage.deptId)
      if(window.localStorage.deptId!=undefined)
      {
        return;
      }
      if(window.localStorage.user)
      {
        var user = JSON.parse(window.localStorage.user);
        if(user.sub)
        {
          let para = { userId:user.sub,isMainJob:1 };
          GetUserPostList(para).then(res=>{
            // console.log(res);
            if(res.status==200)
            {
              var length = res.data.data.length;
              if(length>0)
              {
                window.localStorage.setItem('deptName',res.data.data[0].deptName);
                window.localStorage.setItem('deptId',res.data.data[0].deptId);
              }
            }
          })
        }
      }
    }
  },
  mounted() {
    // console.log(this.$route.path);
    var NavigationBar = JSON.parse(
      window.localStorage.menu ? window.localStorage.menu : null
    );
    this.riliData.push({
      a:'aa',
      d:'cc'
    })
    this.riliData.push({
      a:'bb',
      d:'dd'
    })
    //  this.$api.GetCurrentUserInfo().then((res) => {
    //   console.log()
    //   if (res.data.response != null) {
    //     // this.userForm = res.data.response;
    //     this.imageUrl = res.data.response.ImageURL
    //     // this.userForm.Hiredate = util.formatDate.format(
    //     //   new Date(this.userForm.Hiredate),
    //     //   "yyyy-MM-dd"
    //     // );
    //   }
    // });
    // var NavigationBar = global.antRouter;

    if (this.menus.length <= 0 && NavigationBar && NavigationBar.length >= 0) {
      this.menus = NavigationBar;
      this.defaultActiveIndex = window.localStorage.menuKey
        ? window.localStorage.menuKey
        : "0";
      this.handleSelectMenu(this.defaultActiveIndex);
    }
   
    // 友盟统计添加
    const script = document.createElement("script");
    script.src ="https://hm.baidu.com/hm.js?2a2c775b2e8ceb37bd77cd3fc2acb3e5";   //该路径为点击统计代码后，页面中第一个 文字统计 script代码
    script.language = "JavaScript";
    document.body.appendChild(script);
  },
  updated() {
    //console.log(this.$route.path);
    var user = JSON.parse(
      window.localStorage.user ? window.localStorage.user : null
    );
    if (user) {
      this.sysUserName = user.name || "";
      this.sysUserAvatar = user.avatar || "../assets/user.png";
    }

    var NavigationBar = JSON.parse(
      window.localStorage.menu ? window.localStorage.menu : null
    );

    if (NavigationBar && NavigationBar.length >= 0) {
      if (
        this.menus.length <= 0 ||
        JSON.stringify(this.menus) != JSON.stringify(NavigationBar)
      ) {
        this.menus = NavigationBar;
        this.defaultActiveIndex = window.localStorage.menuKey
          ? window.localStorage.menuKey
          : "0";
        this.handleSelectMenu(this.defaultActiveIndex);
      }
    }
  },
  watch: {
    // 对router进行监听，每当访问router时，对tags的进行修改
    $route: async function (newValue, from) {

      const tags = this.$refs.tag;
      this.$nextTick(() => {
        if (tags) {
          for (const tag of tags) {
            if (tag.to.path === this.$route.path) {
              this.$refs.scrollPane.moveToTarget(tag, tags);
              break;
            }
          }
        }
      });
      if (window._czc) {
        let location = window.location;
        let contentUrl = location.pathname + location.hash;
        let refererUrl = "/";
        window._czc.push(["_trackPageview", contentUrl, refererUrl]);
      }
    },
  },
  created() {
    this.ispc = window.screen.width > 680;
    let self=this;
    // self.signalr.on('Show',res=>{
    //   //console.log(res);
    //   if(self.clientid==null)
    //   {
    //     // console.log('get clientid successfully!')
    //     self.clientid=res.clientId;
    //   }
    //   if(res.type == 2){
    //     this.msgcontent = res.content;
    //     this.msgshowtime = res.expireSeconds * 1000;
    //     //this.MessageTip();
    //     // console.info(this.msgcontent);
    //   }
    // });
    // this.signalrTryConnect();
    // self.signalr.onclose(async () => {
    //     console.info('connection close!');
    //     self.signalrTryConnect();
    // });
    self.loadDeptInfo();
  },
  computed:{
    IsLoading: function () {
      //console.log(this.$store.getters.loading)
      // return this.$store.getters.loading
    },

    
  }

};
</script>

<style lang="css">
@import "./style/home.css";

.el-menu-vertical-demo {
  /*width: 230px;*/
}
.el-pager li.active {
  color: #eb1964;
  cursor: default;
}
.el-breadcrumb {
  line-height: 60px !important;
}
.el-scrollbar__view {
  height: 100%;
}
</style>

<style>
.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
.menu-collapsed .el-icon-arrow-right:before {
  display: none;
}
.calc-height {
  height: calc(100vh - 300px);
}
.tags {
  position: relative;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
  background: #f0f0f0;
}

.tags ul {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: none;
}

.tags-li {
  float: left;
  margin: 3px 5px 2px 3px;
  border-radius: 3px;
  font-size: 13px;
  overflow: hidden;
  height: 23px;
  line-height: 23px;
  border: 1px solid #e9eaec;
  background: #fff;
  padding: 3px 5px 4px 12px;
  vertical-align: middle;
  color: #666;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.tags-li-icon {
  cursor: pointer;
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li-title {
  float: left;
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
  text-decoration: none;
}

.tags-li.active {
  /*color: #fff;*/
  /*border: 1px solid #10B9D3;*/
  /*background-color: #10B9D3;*/
}

.tags-li.active .tags-li-title {
  /*color: #fff;*/
}

.tags-close-box {
  box-sizing: border-box;
  text-align: center;
  z-index: 10;
  float: right;
  margin-right: 1px;
  line-height: 2;
}
/*定义全局样式*/
.new_header {
  background-color: #ffffff;
}
.select_form {
  padding-bottom: 0px;
  margin-top: 15px;
  margin-left: 15px;
}
.new_css thead tr th {
  /* background-color: #eb1964 !important; */
}

.new_table {
  width: 100%;
  margin-top: 15px;
}
.new_css .el-table th > .cell {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  /* color: #ffffff; */
}
</style>
<style>
/*.logoban{*/
/*width: auto !important;*/
/*}*/
.news-dialog {
  background: #fff;
  z-index: 3000 !important;
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 360px;
  top: 60px !important;
  left: 0 !important;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  -webkit-transform: translate(100%);
  z-index: 40000;
  left: auto !important;
  right: 0 !important;
  transform: translate(0);
}

.news-dialog .el-dialog {
  margin: auto !important;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-shadow: none;
  width: 100%;
}

.news-dialog.show {
  transform: translate(0);
}

.tag-new {
  width: 100%;
  margin: 10px 0;
}

@media screen and (max-width: 680px) {
  .collapsedLogo {
    display: none;
  }

  .el-dialog {
    width: 90% !important;
  }

  .content-expanded {
    max-width: 100% !important;
    max-height: calc(100% - 60px);
  }

  .mobile-ex {
    background: #fff;
    z-index: 3000;
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 260px;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
    -webkit-transform: translate(100%);
    z-index: 40000;
    left: auto;
    right: 0;
    transform: translate(100%);
  }

  .mobile-ex.menu-collapsed-mobile {
    transform: translate(0);
  }

  .el-menu--collapse {
    width: 100% !important;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner,
  .el-cascader.el-cascader--medium {
    width: 100% !important;
  }

  .toolbar.roles {
    width: 100% !important;
  }

  .toolbar.perms {
    width: 800px !important;
  }

  .toolbar.perms .box-card {
    width: 100% !important;
  }

  .login-container {
    width: 300px !important;
  }

  .count-test label {
  }

  .content-wrapper .tags {
    margin: 0px;
    padding: 0px;
  }
}
</style>

<style>
.tags-view-container {
  height: 34px;
  width: calc(100% - 60px);
  /*background: #fff;*/
  /*border-bottom: 1px solid #d8dce5;*/
  /*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);*/
  float: left;
}

.tags-view-container .tags-view-wrapper .tags-view-item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 26px;
  line-height: 26px;
  border: 1px solid #d8dce5;
  color: #495060;
  background: #fff;
  padding: 0 8px;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 4px;
}

.tags-view-container .tags-view-wrapper .tags-view-item:first-of-type {
  margin-left: 15px;
}

.tags-view-container .tags-view-wrapper .tags-view-item:last-of-type {
  margin-right: 15px;
}

.tags-view-container .tags-view-wrapper .tags-view-item.active {
  /*background-color: #42b983;*/
  /*color: #fff;*/
  /*border-color: #42b983;*/
}

.tags-view-container .tags-view-wrapper .tags-view-item.active::before {
  content: "";
  background: #2d8cf0;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  margin-right: 2px;
}

.tags-view-container .contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}

.tags-view-container .contextmenu li {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}

.tags-view-container .contextmenu li:hover {
  background: #eee;
}
</style>

<style>
.tags-view-wrapper .tags-view-item .el-icon-close {
  width: 16px;
  height: 16px;
  vertical-align: 2px;
  border-radius: 50%;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform-origin: 100% 50%;
}

.tags-view-wrapper .tags-view-item .el-icon-close:before {
  transform: scale(0.6);
  display: inline-block;
  vertical-align: -3px;
}

.tags-view-wrapper .tags-view-item .el-icon-close:hover {
  background-color: #ef2b74;
  color: #fff;
}
.messagediv,
.messagecard {
  height: 600px;
}
.messagemanagediv {
  max-height: 400px;
  overflow: auto;
}
.badgeitem {
  margin-top: 10px;
  /* margin-right: 10px; */
}
.logo {
  background-color: black;
}
.mytag {
  width: 50%;
  margin-left: -10%;
  cursor: pointer;
}
.el-pagination {
  white-space: nowrap;
  padding: 20px 5px;
  color: #303133;
  font-weight: 700;
}
.el-dialog__header {
    text-align: center;
    /* height:45px; */
    background-color: #409eff;
}
.el-dialog__title{
  color:#fff
}
.el-dialog__headerbtn .el-dialog__close {
    color: #fff;
}
.msgclass {
  height: auto;
  font-weight: bold;
  background-color:#FFFACD;
}
</style>


