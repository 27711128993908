import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";
import jwt from 'jsonwebtoken'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: null,
        tokenExpire: null,
        tagsStoreList: [],
        topNub: 0,
        fullScreen:true,
        value1:true,
        language: Cookies.get("language") || "zh-CN",
        topNavState: "home",
        leftNavState: "home",
        loading: 0,
        //�û���¼����
        //�û���¼
        access_token: localStorage.getItem("wtaccess_token") || null,
        refresh_token: localStorage.getItem("wtrefresh_token") || null,
        user: localStorage.getItem("user") || [],
        //�Ƿ��¼ ��Ŀ�и�Ϊ��ʵ��¼��Ϣ�жϣ���token
        isLogin: localStorage.getItem("wtaccess_token") ? true : false,
    },
    mutations: {
        login(state, v) {
            //�����ݵ������ȱ��浽localStorage��
            localStorage.setItem("wtaccess_token", v.access_token);
            localStorage.setItem("wtrefresh_token", v.refresh_token);
            // localStorage.setItem("userInfo", jwt.decode(v.access_token))
            // state.userInfo = jwt.decode(v.access_token)

            localStorage.setItem("user", JSON.stringify(jwt.decode(v.access_token)))
            state.user = JSON.stringify(jwt.decode(v.access_token))
            state.access_token = v.access_token;
            state.refresh_token = v.refresh_token;
            // ֮������޸�state�е�״̬
            state.isLogin = true;
        },
        setnub(state, v) {
            state.topNub = v

        },
        setvalue1(state,v){
            state.value1 = v
        },
        setnub1(state, v) {

            state.fullScreen = v
        },
        loginSingle(state, access_token, refresh_token) {
            localStorage.setItem("wtaccess_token", access_token);
            localStorage.setItem("wtrefresh_token", refresh_token);
            localStorage.setItem("user", JSON.stringify(jwt.decode(access_token)))
            state.access_token = access_token;
            state.refresh_token = refresh_token;
            state.isLogin = true;
        },
        logout(state) {
            state.userInfo = null;
            state.access_token = null;
            state.refresh_token = null;
            localStorage.setItem("wtaccess_token", "");
            localStorage.setItem("wtrefresh_token", "");
            localStorage.setItem("userInfo", "");
            localStorage.removeItem("deptId");
            localStorage.removeItem("deptName");
            state.isLogin = false;
        },

        SET_LOADING: (state, loading) => {
            loading ? ++state.loading : --state.loading;
        },
        CLEAN_LOADING: (state) => {
            state.loading = 0;
        },
    },
    actions: {
        setLanguage({ commit }, language) {
            commit("SET_LANGUAGE", language);
        },
        SetLoding({ commit }, boolean) {
            commit("SET_LOADING", boolean);
        },
        CLEANLOADING({ commit }) {
            commit("CLEAN_LOADING");
        },
    },
    getters: {
        userInfo(state) {
            return state.userInfo;
        },
        isLogin(state) {
            return state.isLogin;
        },
        loading(state) {
            if (state.loading < 0) {
                return 0;
            } else {
                return state.loading;
            }
        },
    },
});
