import axios from "axios";
// import router from '../routerManuaConfig'
import router from "../router/index";
import store from "../store";
import Qs from "qs";
import Vue from "vue";
import {RefreshToken} from '../api/oa';
// import applicationUserManager from "../Auth/applicationusermanager";

// let base = "";
// let base1 = 'http://uc.gradgroup.cn';
// 如果是IIS部署，用这个，因为 IIS 只能是 CORS 跨域，不能代理
let base = process.env.NODE_ENV=="production"? '/api':'/api';

// 请求延时
axios.defaults.timeout = 20000;
function refreshToken() {
    // instance是当前request.js中已创建的axios实例
    // const params = {};
    // params.grant_type = 'refresh_token';
    // params.client_id = 'grad_wt_admin';
    // params.refresh_token = store.state.refresh_token;
    // // refreshRequest.post(loginByPass, params, {
    // //   header: {
    // //     'Content-Type': 'application/x-www-form-urlencoded'
    // //   },
    // //   custom: {
    // //     isCls: 1
    // //   }
    // return axios.post(`https://sts.gradgroup.cn/connect/token`, Qs.stringify(params), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then(res => res.data)



    // const params = {};
    // params.grant_type = 'refresh_token';
    // params.client_id = 'client-grad-pwd';
    // params.client_secret = 'T78JVybD7u2kBcss';
    // params.refresh_token = store.state.wtrefresh_token;
    // return axios.post(`https://api.gradgroup.cn/oauth/token`, Qs.stringify(params), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then((res) => res.data)





    
    const params = {};
    params.grant_type = 'refresh_token';
    params.client_id = 'client-grad-pwd';
    params.client_secret = 'T78JVybD7u2kBcss';
    params.refresh_token = store.state.refresh_token;
    return RefreshToken(Qs.stringify(params)).then((res)=>res)

}
// 是否正在刷新的标记
let isRefreshing = false
var storeTemp = store;
// 重试队列
let requests = []


axios.defaults.baseURL = base;
// axios.defaults.baseURL = ''
axios.interceptors.request.use(

    config => {
        // console.log(store.state.access_token)
        if (store.state.access_token) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = "Bearer " + store.state.access_token;
        }

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
axios.interceptors.response.use(

    (response) => {
        // console.log(response);
        // loadingCount--
        // console.log("关闭时的"+loadingCount)
        // if (apiLoading && loadingCount == 0) {
        //        glo_loading.loadingHide()
        // }
        store.dispatch("SetLoding", false);
        return response;
    },
    (error) => {

        store.dispatch("SetLoding", false);
        // apiLoading ? glo_loading.loadingHide() : ''
        // 超时请求处理
        var originalRequest = error.config;
        // console.log(error);
        if (error.response) {
            
            // console.log(error.response);
            //401
            if (error.response.status == 401) {
                if(error.response.data)
                {
                    var code = error.response.data.code;
                    if(code == 1002)
                    {
                        console.log('1002 a')
                        store.commit('logout');
                        router.push({ path: '/Login' });
                    }
                    if(code==301 || code==1009)
                    {
                        return error.response;
                    }
                }
                // store.commit('logout');
                // router.push({ path: '/Login' });
                // return;
                // store.commit('logout')
                // router.push({ path: '/Login' })
                if (!isRefreshing) {
                    console.log('b')
                    isRefreshing = true
                    console.log('d')
                    return refreshToken().then((res) => {
                        console.log('refreshToken res')
                        // console.log(res);
                        if(res.code==1002)
                        {
                            console.log('1002')
                        }
                        // if(res.code==1002)
                        // {
                        //     console.log('2001')
                        //     store.commit('logout');
                        //     isRefreshing = false
                        //     router.push({ path: '/Login' });
                        // }else{
                        //     console.log('login')
                        //     store.commit("login", res);
                        //     error.config.headers.Authorization = "Bearer " + res.access_token;
                        //     return axios(error.config)
                        // }
                        if(res.status==200)
                        {
                            if(res.data.access_token)
                            {
                                console.log('re login')
                                store.commit("login", res.data);
                                error.config.headers.Authorization = "Bearer " + res.data.access_token;
                                // console.log(error);
                                // console.log(error.config);
                                error.config.baseURL='';
                                // return axios(error.config)
                                requests.forEach(cb => cb());
                                requests=[]
                                return axios(error.config)
                            }else{
                                console.log('2001')
                                store.commit('logout');
                                isRefreshing = false
                                router.push({ path: '/Login' });
                            }
                        }else{
                            console.log('2001')
                            store.commit('logout');
                            isRefreshing = false
                            router.push({ path: '/Login' });
                        }
                    }).catch(res => {
                        console.log('catch')
                        console.error('refreshtoken error =>', res)
                        store.commit('logout');
                        isRefreshing = false
                        router.push({ path: '/Login' });
                    }).finally(() => {
                        isRefreshing = false
                    })
                }
                else{
                    // return new Promise(resolve=>{
                    //     console.log(resolve)
                    // })
                    return new Promise((resolve) => {
                        // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
                        requests.push(() => {
                            originalRequest.baseURL='';
                            // // 给url 增加时间戳 避免请求缓存影响
                            if( originalRequest.url.indexOf('?') > -1) {
                                originalRequest.url = originalRequest.url + '&n='+ new Date().getTime()
                            } else  {
                                originalRequest.url = originalRequest.url + '?n='+ new Date().getTime()
                            }
                            // originalRequest.headers['Authorization'] = "Bearer " + store.state.access_token
                            originalRequest.headers.Authorization = `Bearer ${store.state.access_token}`              
                            resolve(axios(originalRequest))
                        })
                    })
                }
                
            }else{
                // console.log(error.response)
                return Promise.reject(error)
            }
        }
        return error.response; // 返回接口返回的错误信息
    }
);

export default axios;
