import axios from "./api";

// 用户管理
export function getUserListPage (params) {
  // return axios.get(`/uc/api/User/GetUserNameClaimPageByName`, {
  //   params: params,
  // });
  return axios.get(`/org/user/getUserList`, {
    params: params,
  });
}
export function removeUser (params) {
  return axios.delete(`/uc/api/User/DeleteUserById`, { params: params });
}
// 解锁用户
export function putUpdateUserUnLockById(params) {
    return axios.put(`/uc/api/User/UpdateUserUnLockById`, params);
}
// 锁定用户
export function putUpdateUserLock(params) {
    return axios.put(`/uc/api/User/UpdateUserLock`, params);
}

export function editUser (params) {
  return axios.put(`/uc/api/User/UpdateUserAndNameClaim`, params);
}
export function addUser (params) {
  return axios.post(`/uc/api/User/AddUserNameClaim`, params);
}
export function batchRemoveUser (params) {
  return axios.delete(`/wt/Claims/BatchDelete`, { params: params }); //没做
}
export function GetUserChooseList (params) {
  // return axios.post(`/uc/api/User/GetUserAllByIds`, params);
  return axios.get(`/org/user/getUserInfoByIds`, {
    params: params,
  });
}
export function findUserDetailsByUserIds (params) {
  return axios.post(`/org/user/findUserDetailsByUserIds`, params);
}
export function SetPassword (params) {
  return axios.put(`/uc/api/User/UpdateUserPassword`, params);
}
export function GetProbationSubmitInfoPageList (params) {
  return axios.get(`/wt/Probation/GetSubmitInfoPageList`, {
    params: params,
  });
}
export function GetProbationSaveState (params) {
  return axios.get(`/wt/Probation/SaveState`, { params: params });
}
//删除试用
export function DeleteProbationInfo (params) {
  return axios.delete(`/wt/Probation/DelInfo`, { params: params });
}
// 角色管理
export function getRoleListPage (params) {
  return axios.get(`/wt/Role/GetRolePageByName`, { params: params });
}
export function removeRole (params) {
  return axios.delete(`/wt/Role/DeleteRoleById`, { params: params });
}
export function editRole (params) {
  return axios.put(`/wt/Role/UpdateRole`, params);
}
export function addRole (params) {
  return axios.post(`/wt/Role/AddRole`, params);
}
export function GetRoleUserList (params) {
  return axios.get(`/wt/Role/GetUserClaimViewPageByRoleIdAndName`, {
    params: params,
  });
}

//用户角色管理模块
export function AddUserRole (params) {
  return axios.post(`/wt/Role/UpdateUserKeys`, params);
}
export function DeleteUserRole (params) {
  return axios.delete(`/wt/Role/DeleteRoleUserByRoleIdAndUserId`, {
    params: params,
  });
}

// 接口模块管理
export function getModuleListPage (params) {
  return axios.get(`/wt/Interface/GetInterfaceList`, { params: params });
}
export function getModuleList (params) {
  return axios.get(`/wt/Interface/Get`, { params: params });
}
export function removeModule (params) {
  return axios.delete(`/wt/Interface/delete`, { params: params });
}
export function editModule (params) {
  return axios.put(`/wt/Interface/put`, params);
}
export function addModule (params) {
  return axios.post(`/wt/Interface/post`, params);
}
//字典模块管理
export function getSupplyCategoryList (params) {
  return axios.get(`/wt/Dictionary/get`, { params: params });
}
//部门模块管理
export function GetChildrenList (params) {
  // return axios.get(`/uc/api/Department/GetDepartmentNodeAllByParentId`, {
  //   params: params,
  // });
  return axios.get(`/org/dept/orgDeptList`, { params: params });
}
export function GetManagePost (params) {
  return axios.get(`/uc/api/Post/GetPostNodeManageByDepartmentId`, {
    params: params,
  });
}
export function AddDept (params) {
  return axios.post(`/uc/api/Department/AddDepartment`, params);
}
export function Update (params) {
  return axios.put(`/uc/api/Department/UpdateDepartment`, params);
}
export function DeleteDept (params) {
  return axios.delete(`/uc/api/Department/DeleteDepartmentById`, {
    params: params,
  });
}
export function GetDepartment (params) {
  return axios.get(`/uc/api/Department/GetDepartmentNodeById`, {
    params: params,
  });
}
export function GetDepartmentPostUserList (params) {
  // return axios.get(`/uc/api/Department/GetDepartmentUserViewPageById`, {
  //   params: params,
  // });
  return axios.get(`/org/user/getUserList`, { params: params });
}
export function GetDepartmentList (params) {
  return axios.get(`/uc/api/Department/GetDepartmentNodeAllByName`, {
    params: params,
  });
}
export function GetDepartmentTree (params) {
  // return axios.get(`/uc/api/Department/GetTreeViewByRootId`, {
  //   params: params,
  // });
  return axios.get(`/org/dept/orgDeptList`, {
    params: params,
  });
}
export function GetFormatDepartmentList (params) {
  return axios.post(`/org/dept/getWorkTaskDept`, params);
}
//部门角色
export function GetDepartmentRoleList (params) {
  return axios.get(`/wt/Role/GetDepartmentKeysByRoleId`, {
    params: params,
  });
}
export function AssignDepartmentRole (params) {
  return axios.post(`/wt/Role/UpdateDepartmentKeys`, params);
}
//部门类型模块管理
export function GetDepartmentTypes (params) {
  // return axios.get(`/uc/api/Department/GetDepartmentTypePageByName`, {
  return axios.get(`/org/dept/getAllDeptTypeList`, {
    params: params,
  });
}
export function AddDptTypes (params) {
  return axios.post(`/uc/api/Department/AddDepartmentType`, params);
}
export function UpdateDptTypes (params) {
  return axios.put(`/uc/api/Department/UpdateDepartmentType`, params);
}
export function DeleteDptTypes (params) {
  return axios.delete(`/uc/api/Department/DeleteDepartmentTypeById`, {
    params: params,
  });
}
//部门级别管理模块
export function GetDepartmentRanks (params) {
  return axios.get(`/uc/api/Department/GetDepartmentRankPageByName`, {
    params: params,
  });
}
export function AddDptRanks (params) {
  return axios.post(`/uc/api/Department/AddDepartmentRank`, params);
}
export function UpdateDptRanks (params) {
  return axios.put(`/uc/api/Department/UpdateDepartmentRank`, params);
}
export function DeleteDptRanks (params) {
  return axios.delete(`/uc/api/Department/DeleteDepartmentRankById`, {
    params: params,
  });
}
//职务级别管理模块
export function GetPostTypes (params) {
  return axios.get(`/uc/api/Post/GetPostRankPageByName`, { params: params });
}
export function AddPostRank (params) {
  return axios.post(`/uc/api/Post/AddPostRank`, params);
}
export function UpdatePostRank (params) {
  return axios.put(`/uc/api/Post/UpdatePostRank`, params);
}
export function DeletePostRank (params) {
  return axios.delete(`/uc/api/Post/DeletePostRankById`, { params: params });
}
//职务工作类型管理模块
export function GetWorkTypes (params) {
  return axios.get(`/uc/api/Post/GetPostWorkTypePageByName`, {
    params: params,
  });
}
//按钮模块管理
export function buttonEdit (params) {
  return axios.put(`/wt/Button/put`, params);
}
export function buttonPost (params) {
  return axios.post(`/wt/Button/post`, params);
}
export function buttonDelete (params) {
  return axios.delete(`/wt/Button/delete`, { params: params });
}
export function GetButtonInterfaceIds (params) {
  return axios.get(`/wt/Button/GetButtonInterfaceIds`, { params: params });
}
//职务模块管理
export function GetPostChildrenList (params) {
  return axios.get(`/uc/api/Post/GetPostNodeAllById`, { params: params });
}
export function GetPostList (params) {
  return axios.get(`/uc/api/Post/GetPostNodeAllByName`, { params: params });
}
export function GetPost (params) {
  return axios.get(`/uc/api/Post/GetPostNodeById`, { params: params });
}
export function AddPost (params) {
  return axios.post(`/uc/api/Post/AddPost`, params);
}
export function UpdatePost (params) {
  return axios.put(`/uc/api/Post/UpdatePost`, params);
}
export function DeletePost (params) {
  return axios.delete(`/uc/api/Post/DeletePostById`, { params: params });
}
export function GetPostTree (params) {
  // return axios.get(`/uc//api/Post/GetTreeViewByRootId`, { params: params });
  return axios.get(`/org/job/getJobList`, { params: params });
}
//职务角色
export function GetPostRoleList (params) {
  return axios.get(`/wt/Role/GetPostKeysByRoleId`, { params: params });
}
export function AssignPostRole (params) {
  return axios.post(`/wt/Role/UpdatePostKeys`, params);
}
//职务用户关联管理模块
export function GetPostUserList (params) {
  return axios.get(`/uc/api/Post/GetPostUserViewPageByPostId`, {
    params: params,
  });
}
export function GetUserPostList (params) {
  return axios.get(`/uc/api/Post/GetPostUserViewAllByUserId`, {
    params: params,
  });
}
export function SetPrincipal (params) {
  return axios.put(`/uc/api/Post/UpdatePostPrincipal`, params);
}
export function DeleteUser (params) {
  return axios.delete(`/uc/api/Post/DeletePostUserByPostIdAndUserId`, {
    params: params,
  });
}
export function AddNewUser (params) {
  return axios.post(`/uc/api/Post/AddPostUser`, params);
}
export function BacthPostUsers (params) {
  return axios.post(`/uc/api/Post/BacthAddDeletePostUser`, params);
}
export function GetPostUserPrincipalList (params) {
  return axios.get(`/uc/api/Post/GetPostUserViewPageByUserCodeOrName`, {
    params: params,
  });
}
export function GetPostUserViewPageByDto (params) {
  return axios.post(`/uc/api/Post/GetPostUserViewPageByDto`, params);
}
// 菜单模块管理
export function getPermissionListPage (params) {
  return axios.get(`/wt/Menu/get`, { params: params });
}
export function getPermissionTreeTable (params) {
  return axios.get(`/wt/Menu/GetMenuTreeTable`, { params: params });
}
export function removePermission (params) {
  return axios.delete(`/wt/Menu/delete`, { params: params });
}
export function editPermission (params) {
  return axios.put(`/wt/Menu/put`, params);
}

export function addPermission (params) {
  return axios.post(`/wt/Menu/post`, params);
}
export function addRolePermission (params) {
  return axios.post(`/wt/Menu/Assign`, params);
}
export function getNavigationBar (params) {
  return axios
    .get(`/wt/Menu/GetNavigationBar`, { params: params })
    .then((res) => res.data);
}
export function GetLinkById (params) {
  return axios.get(`/wt/Menu/GetLinkById`, { params: params });
}
// 应聘登记
export function AddUserBaseInfoLogIn (params) {
  console.log(params);
  return axios.post(`/wt/UserBaseInfo/AddUserBaseInfo`, params);
}
// 应聘登记无登陆
export function AddUserBaseInfo(params) {
    console.log(params);
    return axios.post(`/wt/UserBaseInfo/AddUserBaseInfo`, params);
}
export function getUserBaseInfoList (params) {
  return axios.get(`/wt/UserBaseInfo/GetUserBaseInfoList`, {
    params: params,
  });
}
export function getUserBaseInfo (params) {
  return axios.get(`/wt/UserBaseInfo/GetUserBaseInfo`, { params: params });
}
export function UpdateUserBaseInfo (params) {
  return axios.put(`/wt/UserBaseInfo/UpdateUserBaseInfo`, params);
}
export function AddInterview (params) {
  return axios.post(`/wt/UserInterview/AddInterview`, params);
}
// 获取面试列表 
export function getInterviewList (params) {
  return axios.get(`/wt/UserInterview/GetInterviewList`, {
    params: params,
  });
}
export function getPositiveDetail (params) {
  return axios.get(`/wt/Probation/GetInfo`, { params: params });
}
export function getGetPositiveDetail (params) {
  return axios.get(`/wt/Positive/GetInfo`, { params: params });
}
//删除转正
export function DeletePositiveInfo (params) {
  return axios.delete(`/wt/Positive/DelInfo`, { params: params });
}

//获取所有公司列表
export function getOrganizeList (params) {
  return axios.get(`/uc/api/Department/GetOrganizationAll`);
}
//获取所有试用记录
export function getProbationPageList (params) {
  return axios.get(`/wt/Probation/GetInfoPageList`, { params: params });
}
//获取所有试用记录
export function getProbationInfoList (params) {
  return axios.get(`/wt/Probation/GetInfoList`, { params: params });
}

//更新试用记录
export function UpdateProbation (params) {
  return axios.put(`/wt/Positive/UpdateProbation`, params);
}
//更新转正记录
export function UpdatePositive (params) {
  return axios.put(`/wt/Positive/UpdateInfo`, params);
}
//更新面试记录
export function UpdateInterview (params) {
  return axios.put(`/wt/UserInterview/UpdateInterview`, params);
}
//获取所有证书信息
export function GetCertificatePageList (params) {
  return axios.get(`/wt/Certificate/GetInfoPageList`, { params: params });
}
export function AddCertificate (params) {
  return axios.post(`/wt/Certificate/AddInfo`, params);
}

export function UpdateCertificate (params) {
  return axios.put(`/wt/Certificate/UpdateInfo`, params);
}
//获取所有证书类型
export function GetCertificateTypePageList (params) {
  return axios.get(`/wt/DicCertificate/GetInfoPageList`, {
    params: params,
  });
}
export function AddCertificateType (params) {
  return axios.post(`/wt/DicCertificate/AddInfo`, params);
}
export function UpdateCertificateType (params) {
  return axios.put(`/wt/DicCertificate/UpdateInfo`, params);
}
export function GetCertificateByType (params) {
  return axios.get(`/wt/DicCertificate/GetInfoList`, { params: params });
}
//获取社保五险字典表列表
export function GetDicInsurancePageList (params) {
  return axios.get(`/wt/DicInsurance/GetInfoPageList`, { params: params });
}
export function AddDicInsurance (params) {
  return axios.post(`/wt/DicInsurance/AddInfo`, params);
}
export function UpdateDicInsurance (params) {
  return axios.put(`/wt/DicInsurance/UpdateInfo`, params);
}
export function GetInsuranceMonthPageList (params) {
  return axios.get(`/wt/InsuranceMonth/GetInfoPageList`, {
    params: params,
  });
}
export function AddInsuranceMonth (params) {
  return axios.post(`/wt/InsuranceMonth/AddInfo`, params);
}
export function DeleteInsuranceMonth (params) {
  return axios.delete(`/wt/InsuranceMonth/DelInfo`, { params: params });
}
export function GetInsuranceReportPageList (params) {
  return axios.get(`/wt/InsuranceReport/GetInfoPageList`, {
    params: params,
  });
}
export function UpdateInsuranceReport (params) {
  return axios.put(`/wt/InsuranceReport/UpdateInfo`, params);
}
export function GetInsurancePersonPageList (params) {
  return axios.get(`/wt/InsurancePerson/GetInfoPageList`, {
    params: params,
  });
}
export function AddInsurancePerson (params) {
  return axios.post(`/wt/InsurancePerson/AddInfo`, params);
}
export function AddReduceInfo (params) {
  return axios.post(`/wt/InsurancePerson/ReduceInfo`, params);
}
export function UpdateInsurancePerson (params) {
  return axios.put(`/wt/InsurancePerson/UpdateInfo`, params);
}
export function UpdateInsuranceReportSalary (params) {
  return axios.put(`/wt/InsuranceReport/BatchUpdateInfoSalary`, params);
}

//调动列表
export function GetTransferPageList (params) {
  return axios.get(`/wt/Transfer/GetInfoPageList`, { params: params });
}
export function GetDepartmentOrg (params) {
  return axios.get(`/uc/api/Department/GetDepartmentOrganizationById`, {
    params: params,
  });
}
export function AddTransferInfo (params) {
  return axios.post(`/wt/Transfer/AddInfo`, params);
}
export function UpdateTransfer (params) {
  return axios.put(`/wt/Transfer/UpdateInfo`, params);
}
export function GetDicContractTypePageList (params) {
  return axios.get(`/wt/DicContract/GetInfoPageList`, { params: params });
}
export function AddDicContractType (params) {
  return axios.post(`/wt/DicContract/AddInfo`, params);
}
export function UpdateDicContractType (params) {
  return axios.put(`/wt/DicContract/UpdateInfo`, params);
}
export function GetContractPageList (params) {
  return axios.get(`/wt/Contract/GetInfoPageList`, { params: params });
}
export function AddContract (params) {
  return axios.post(`/wt/Contract/AddInfo`, params);
}
export function UpdateContract (params) {
  return axios.put(`/wt/Contract/UpdateInfo`, params);
}
export function UpdateSetInfoSate (params) {
  console.log(params);
  return axios.put(`/wt/InsuranceMonth/SetInfoSate`, params);
}
export function GetPersonAddExcelList (params) {
  return axios.get(`/wt/InsuranceMonth/GetPersonAddExcelList`, {
    params: params,
  });
}
export function GetPersonReduceExcelList (params) {
  return axios.get(`/wt/InsuranceMonth/GetPersonReduceExcelList`, {
    params: params,
  });
}
export function GetPersonHistoryList (params) {
  return axios.get(`/wt/InsuranceMonth/GetPersonHistoryList`, {
    params: params,
  });
}
export function GetTransferInfoDetail (params) {
  return axios.get(`/wt/Transfer/GetInfo`, { params: params });
}

//职称管理
export function GetProfessionnfoList (params) {
  return axios.get(`/wt/Profession/GetInfoPageList`, { params: params });
}
export function AddProfessionInfo (params) {
  return axios.post(`/wt/Profession/AddInfo`, params);
}
export function UpdateProfession (params) {
  return axios.put(`/wt/Profession/UpdateInfo`, params);
}
export function GetProfessionTopList (params) {
  return axios.get(`/wt/ProfessionTop/GetInfoPageList`, { params: params });
}
export function AddProfessionTopInfo (params) {
  return axios.post(`/wt/ProfessionTop/AddInfo`, params);
}
export function UpdateProfessionTop (params) {
  return axios.put(`/wt/ProfessionTop/UpdateInfo`, params);
}
export function GetRewardsList (params) {
  return axios.get(`/wt/Rewards/GetInfoPageList`, { params: params });
}
export function AddRewards (params) {
  return axios.post(`/wt/Rewards/AddInfo`, params);
}
export function AddListRewards(params) {
    return axios.post(`/wt/Rewards/AddInfoList`, params);
}

export function UpdateRewards (params) {
  return axios.put(`/wt/Rewards/UpdateInfo`, params);
}
export function GetHonorList (params) {
  return axios.get(`/wt/Honor/GetInfoPageList`, { params: params });
}
export function AddHonor (params) {
  return axios.post(`/wt/Honor/AddInfo`, params);
}
export function UpdateHonor (params) {
  return axios.put(`/wt/Honor/UpdateInfo`, params);
}
export function GetCertificateDetail (params) {
  return axios.get(`/wt/Certificate/GetInfo`, { params: params });
}
export function GetContractDetail (params) {
  return axios.get(`/wt/Contract/GetInfo`, { params: params });
}
export function GetRewardsDetail (params) {
  return axios.get(`/wt/Rewards/GetInfo`, { params: params });
}
export function GetHonorDetail (params) {
  return axios.get(`/wt/Honor/GetInfo`, { params: params });
}

//获取当前登录用户的信息
export function GetCurrentUserInfo (params) {
  return axios.get(`/wt/UserInfo/GetCurrentUserInfo`, { params: params });
}
export function GetAtPresentBaseInfo (params) {
  return axios.get(`/wt/UserInfo/GetAtPresentBaseInfo`, { params: params });
}
export function ContractGetInfoList (params) {
  return axios.get(`/wt/Contract/GetInfoList`, { params: params });
}
export function SalaryReportGetGroupInfoPageList (params) {
  return axios.get(`/wt/SalaryReport/GetGroupInfoPageList`, {
    params: params,
  });
}
export function CertificateGetInfoList (params) {
  return axios.get(`/wt/Certificate/GetInfoList`, { params: params });
}
export function TransferGetInfoList (params) {
  return axios.get(`/wt/Transfer/GetInfoList`, { params: params });
}
export function HonorGetInfoList (params) {
  return axios.get(`/wt/Honor/GetInfoList`, { params: params });
}
export function DimissionGetInfoList (params) {
  return axios.get(`/wt/Dimission/GetInfoList`, { params: params });
}
export function PositiveGetPositiveList (params) {
  return axios.get(`/wt/Positive/GetInfoList`, { params: params });
}
export function SalaryBanksGetInfoList (params) {
  return axios.get(`/wt/SalaryBanks/GetInfoList`, { params: params });
}
export function ProfessionGetInfoList (params) {
  return axios.get(`/wt/Profession/GetInfoList`, { params: params });
}
export function ProfessionTopGetInfoList (params) {
  return axios.get(`/wt/ProfessionTop/GetInfoList`, { params: params });
}
export function GetAtPresentSalaryBanksInfo (params) {
  return axios.get(`/wt/UserInfo/GetAtPresentSalaryBanksInfo`, {
    params: params,
  });
}
export function UserInfoGetInfoPageList (params) {
  return axios.get(`/wt/UserInfo/GetInfoPageList`, { params: params });
}
//分配权限
export function UserInfoCompetenceAddInfo (params) {
  return axios.post(`/wt/UserInfoCompetence/AddInfoList`, params);
}
export function UserInfoCompetenceGetInfo (params) {
  return axios.get(`/wt/UserInfoCompetence/GetInfoList`, {
    params: params,
  });
}
export function SalaryCompetenceAddInfo (params) {
  return axios.post(`/wt/SalaryCompetence/AddInfoList`, params);
}
export function SalaryCompetenceGetInfo (params) {
  return axios.get(`/wt/SalaryCompetence/GetInfoList`, { params: params });
}
export function DimissionCompetenceAddInfo (params) {
  return axios.post(`/wt/DimissionCompetence/AddInfoList`, params);
}
export function DimissionCompetenceGetInfo (params) {
  return axios.get(`/wt/DimissionCompetence/GetInfoList`, {
    params: params,
  });
}
//证书补贴
export function CertificateMonthGetInfo (params) {
  return axios.get(`/wt/CertificateMonth/GetInfoPageList`, {
    params: params,
  });
}
export function CertificateMonthAddInfo (params) {
  return axios.post(`/wt/CertificateMonth/AddInfo`, params);
}
export function CertificateMonthDelInfo (params) {
  return axios.delete(`/wt/CertificateMonth/DelInfo`, { params: params });
}
export function CertificateMonthSetInfo (params) {
  return axios.put(`/wt/CertificateMonth/SetInfoSate`, params);
}
export function CertificateMonthGetPerson (params) {
  return axios.get(`/wt/CertificateMonth/GetPersonHistoryList`, {
    params: params,
  });
}
export function CertificateReport (params) {
  return axios.get(`/wt/CertificateReport/GetInfoPageList`, {
    params: params,
  });
}
export function CertificateReportUpdate (params) {
  return axios.put(`/wt/CertificateReport/UpdateInfo`, params);
}
export function GetAtPresentContractInfo (params) {
  return axios.get(`/wt/UserInfo/GetAtPresentContractInfo`, {
    params: params,
  });
}
export function GetAttendGroupById (params) {
  return axios.get(`/wt/UserInfo/GetAttendGroupById`, {
    params: params,
  });
}
export function ProbationUpdateInfo (params) {
  return axios.put(`/wt/Probation/UpdateInfo`, params);
}
export function SalaryMonthGetPersonHistoryList (params) {
  return axios.get(`/wt/SalaryMonth/GetPersonHistoryList`, {
    params: params,
  });
}
export function UserInfoGetFileArrayList (params) {
  return axios.get(`/wt/UserInfo/GetFileArrayList`, { params: params });
}
export function UserInfosUpdateFileList (params) {
  return axios.put(`/wt/UserInfo/UpdateFileList`, params);
}

export function UserUpdateUserImage (params) {
  return axios.put(`/wt/UserInfo/UpdateUserImage`, params);
}
export function UserInfoGetRewardsList (params) {
  return axios.get(`/wt/Rewards/GetInfoList`, { params: params });
}
export function SalaryBanksGetOldInfoListList (params) {
  return axios.get(`/wt/SalaryBanks/GetOldInfoList`, { params: params });
}
//转正提醒
export function GetProbationRemindPageList (params) {
  return axios.get(`/wt/Probation/GetInfoRemindPageList`, {
    params: params,
  });
}
//证书提醒
export function GetCertificateRemindPageList (params) {
  return axios.get(`/wt/Certificate/GetInfoRemindPageList`, {
    params: params,
  });
}
//证书有效期提醒
export function GetCertificateValidityPageList(params) {
    return axios.get(`/wt/Certificate/GetInfoValidityPageList`, {
        params: params,
    });
}
//证书补贴提醒
export function GetSalaryRemindPageList (params) {
  return axios.get(`/wt/Certificate/GetSalaryRemindPageList`, {
    params: params,
  });
}
//合同到期提醒
export function GetContractRemindPageList (params) {
  return axios.get(`/wt/Contract/GetInfoRemindPageList`, {
    params: params,
  });
}
export function GetAgeRemindPageList (params) {
  return axios.get(`/wt/UserInfo/GetAgeRemindPageList`, { params: params });
}
//获取模块子节点
export function ModuleGetNodeChildren (params) {
  return axios.get(`/wt/Module/GetModuleNodeByApplicationIdAndParentId`, {
    params: params,
  });
}
//添加模块
export function ModuleAdd (params) {
  return axios.post(`/wt/Module/AddModule`, params);
}
//编辑模块
export function ModuleUpdate (params) {
  return axios.put(`/wt/Module/UpdateModule`, params);
}
//删除模块
export function ModuleDelete (params) {
  return axios.delete(`/wt/Module/DeleteModulebyId`, { params: params });
}
//获取菜单子节点
export function MenuGetNodeChildren (params) {
  return axios.get(`/wt/Menu/GetMenuNodeAllByModuleIdAndParentId`, {
    params: params,
  });
}
//添加菜单
export function MenuAdd (params) {
  return axios.post(`/wt/Menu/AddMenu`, params);
}
//编辑菜单
export function MenuUpdate (params) {
  return axios.put(`/wt/Menu/UpdateMenu`, params);
}
//删除菜单
export function MenuDelete (params) {
  return axios.delete(`/wt/Menu/DeleteMenuById`, { params: params });
}
//获取菜单下按钮集合
export function ButtonGetListByMenuId (params) {
  return axios.get(`/wt/Button/GetButtonAllByMenuId`, { params: params });
}
//添加按钮
export function ButtonAdd (params) {
  return axios.post(`/wt/Button/AddButton`, params);
}
//编辑按钮
export function ButtonUpdate (params) {
  return axios.put(`/wt/Button/UpdateButton`, params);
}
//删除按钮
export function ButtonDelete (params) {
  return axios.delete(`/wt/Button/DeleteButtonById`, { params: params });
}

//获取应用模块菜单按钮树
export function getPermissionTree (params) {
  return axios.get(`/wt/Role/GetNavigationTreeByApplicationId`, {
    params: params,
  });
}
//获取角色已分配权限标识
export function getAssignKeys (params) {
  return axios.get(`/wt/Role/GetNavigationKeysByApplicationIdAndRoleId`, {
    params: params,
  });
}
//设置角色分配权限
export function setAssignKeys (params) {
  return axios.post(`/wt/Role/UpdateNavigationKeys`, params);
}
//人员任免
export function OfficialPersonList (params) {
  return axios.get(`/wt/OfficialPerson/GetInfoPageList`, {
    params: params,
  });
}
export function OfficialPersonInfo (params) {
  return axios.get(`/wt/OfficialPerson/GetInfo`, { params: params });
}
export function OfficialPersonAdd (params) {
  return axios.post(`/wt/OfficialPerson/AddInfo`, params);
}
export function OfficialPersonUpdate (params) {
  return axios.put(`/wt/OfficialPerson/UpdateInfo`, params);
}
//组织调整
export function OfficialOrgList (params) {
  return axios.get(`/wt/OfficialOrg/GetInfoPageList`, { params: params });
}
export function OfficialOrgInfo (params) {
  return axios.get(`/wt/OfficialOrg/GetInfo`, { params: params });
}
export function OfficialOrgAdd (params) {
  return axios.post(`/wt/OfficialOrg/AddInfo`, params);
}
export function OfficialOrgUpdate (params) {
  return axios.put(`/wt/OfficialOrg/UpdateInfo`, params);
}

export function PatentGetInfoPageList (params) {
  return axios.get(`/wt/Patent/GetInfoPageList`, { params: params });
}
export function PatentGetInfoList (params) {
  return axios.get(`/wt/Patent/GetInfo`, { params: params });
}
export function PatentUpdateInfo (params) {
  return axios.put(`/wt/Patent/UpdateInfo`, params);
}
export function PatentAddInfo (params) {
  return axios.post(`/wt/Patent/AddInfo`, params);
}
//添加绩效权限
export function MeritCompetenceAddInfoList (params) {
  return axios.post(`/wt/MeritCompetence/AddInfoList`, params);
}
//获取指定记录
export function MeritCompetenceGetInfoList (params) {
  return axios.get(`/wt/MeritCompetence/GetInfoList`, { params: params });
}
//获取绩效月度列表
export function MeritSalaryGetInfoPageList (params) {
  return axios.get(`/wt/MeritSalary/GetInfoPageList`, { params: params });
}
//添加绩效月度
export function MeritSalaryAddInfo (params) {
  return axios.post(`/wt/MeritSalary/AddInfo`, params);
}

//导入绩效月度内人员
export function MeritSalaryInsertInfo (params) {
  return axios.post(`/wt/MeritSalaryList/InsertInfo`, params);
}
//删除绩效月度内人员
export function MeritSalaryListDelInfo (params) {
  return axios.delete(`/wt/MeritSalaryList/DelInfo`, { params: params });
  //return axios.delete(`/wt/MeritSalaryList/DelInfo`, { params: params });
}

//删除绩效月度
export function MeritSalaryDelInfo (params) {
  return axios.delete(`/wt/MeritSalary/DelInfo`, { params: params });
}
//设置绩效月度状态
export function MeritSalarySetInfoSate (params) {
  return axios.put(`/wt/MeritSalary/SetInfoSate`, params);
}
//获取绩效指定月份的人员记录表
export function MeritSalaryGetPersonHistoryList (params) {
  return axios.get(`/wt/MeritSalary/GetPersonHistoryList`, { params: params });
}
//获取绩效下所有公司列表
export function MeritSalaryListGetGroupFactoryList (params) {
  return axios.get(`/wt/MeritSalaryList/GetGroupFactoryList`, { params: params });
}
export function MeritSalaryListGetInfoPageList (params) {
  return axios.get(`/wt/MeritSalaryList/GetInfoPageList`, { params: params });
}
//绩效月度提交公司
export function MeritSalaryListSubmitInfo (params) {
  return axios.get(`/wt/MeritSalaryList/SubmitInfo`, { params: params });
}
//绩效月度--修改
export function MeritSalaryListUpdateInfo (params) {
  return axios.put(`/wt/MeritSalaryList/UpdateInfo`, params);
}
//绩效月度待审核列表
export function MeritSalaryListGetGroupInfoPageList (params) {
  return axios.get(`/wt/MeritSalaryList/GetGroupInfoPageList`, { params: params });
}
//获取科技成果记录
export function getResearchResultInfoList (params) {
  return axios.get(`/wt/UserInfo/GetResearchResultList`, { params: params });
}

//添加体检记录
export function AddCheckupInfo (params) {
  return axios.post(`/wt/Checkup/AddInfo`, params);
}
//获取指定体检记录
export function GetCheckupInfo (params) {
  return axios.get(`/wt/Checkup/GetInfo`, { params: params });
}
//获取指定人员体检记录
export function GetCheckupInfoList (params) {
  return axios.get(`/wt/Checkup/GetInfoList`, { params: params });
}
//获取体检记录列表
export function GetCheckupInfoPageList (params) {
  return axios.get(`/wt/Checkup/GetInfoPageList`, { params: params });
}
//获取体检记录列表
export function UpdateCheckupInfo (params) {
  return axios.put(`/wt/Checkup/UpdateInfo`, params);
}

//添加岗位职责记录
export function AddPostExplainInfo (params) {
  return axios.post(`/wt/PostExplain/AddInfo`, params);
}
//获取指定岗位职责记录
export function GetPostExplainInfo (params) {
  return axios.get(`/wt/PostExplain/GetInfo`, { params: params });
}
//获取指定岗位职责记录
export function GetPostExplainInfoList (params) {
  return axios.get(`/wt/PostExplain/GetInfoList`, { params: params });
}
//获取岗位职责记录列表
export function GetPostExplainInfoPageList (params) {
  return axios.get(`/wt/PostExplain/GetInfoPageList`, { params: params });
}
//获取岗位职责记录列表
export function UpdatePostExplainInfo (params) {
  return axios.put(`/wt/PostExplain/UpdateInfo`, params);
}
//获取岗位职责记录列表
export function DeletePostExplainInfo (params) {
  return axios.delete(`/wt/PostExplain/DelInfo`, { params: params });
}