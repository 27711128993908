import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/login'
import loginSingle from '../views/Public/loginSingle'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
const Home = () => import('../views/Dashboard/admin/index')
const Thanks = () => import('../views/Thanks')
const NoPage = () => import('../views/404')
const refuse = () => import('../views/refuse')

const printUser = () => import('../views/printUser')
const emptyPage = () => import('../views/emptyPage')
const Layout = () => import('../views/Layout/Layout')
const userTemplate = () => import('../views/userTemplate')
const _import = require('@/router/_import_' + process.env.NODE_ENV)//获取组件的方法

import IdentityServerRefreshToken from "../views/IdentityServerRefreshToken";
Vue.use(Router)

const createRouter = () => new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
      {
          path: '/login', component: Login, name: 'login',
          meta: {
              title: '登录',
              requireAuth: false,
              NoTabPage: true,
              NoNeedHome: true // 添加该字段，表示不需要home模板
          },
          hidden: true
      },
    {
      path: '/404', component: NoPage, name: 'NoPage',
      meta: {
        title: 'NoPage',
        requireAuth: false,
        NoTabPage: true,
        NoNeedHome: true // 添加该字段，表示不需要home模板
      },
      hidden: true
    },
    {
      path: '/emptyPage', component: emptyPage, name: 'emptyPage',
      meta: {
        title: 'emptyPage',
        requireAuth: false,
        NoTabPage: true,
        NoNeedHome: true // 添加该字段，表示不需要home模板
      },
      hidden: true
    },
    {
      path: '/userTemplate', component: userTemplate, name: 'userTemplate',
      meta: {
        title: 'userTemplate',
        requireAuth: false,
        NoTabPage: true,
        NoNeedHome: false // 添加该字段，表示不需要home模板
      },
      hidden: true
    },
   
    {
      path: '/printUser', component: printUser, name: 'printUser',
      meta: {
        title: 'printUser',
        requireAuth: false,
        NoTabPage: true,
        NoNeedHome: true // 添加该字段，表示不需要home模板
      },
      hidden: true
    },
    {
      path: '/refuse', component: refuse, name: 'refuse',
      meta: {
        title: 'refuse',
        requireAuth: false,
        NoTabPage: true,
        NoNeedHome: true // 添加该字段，表示不需要home模板
      },
      hidden: true
    },
    {
      path: '/Thanks', component: Thanks, name: 'Thanks',
      meta: {
        title: 'Thanks',
        requireAuth: false
      },
      hidden: true
    },
    // {
    //   path: '/Task/Mobile/MyHome', component: MyHome, name: 'MyHome',
    //   meta: {
    //     title: 'MyHome',
    //     NoTabPage: true,
    //     NoNeedHome: true,
    //     requireAuth: true
    //   },
    //   hidden: true
    // },
    // {
    //     path: '/Task/Mobile/MyHome',component: MyHome,name: 'MyHome',
    //     meta: {
    //         title: '移动端首页',
    //         NoTabPage: true,
    //         NoNeedHome: true,
    //         requireAuth: true
    //     },
    //     hidden: true
    // },  
    {
      path: '/',
      type: 'home',
      component: Home,
      name: '首页',
      // hidden: true,
      meta: {
        title: '首页',
        requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
      }
    },
    
    {
      path: "/IdentityServerRefreshToken",
      name: "IdentityServerRefreshToken",
      component: IdentityServerRefreshToken,
      meta: {
        title: '刷新',
        NoTabPage: true,
      },
      hidden: true
    },
    {
      path: '*',
      hidden: true,
      redirect: { path: '/404' }
      },
      {
          path: '/loginSingle', component: loginSingle, name: 'loginSingle',
          meta: {
              title: 'loginSingle',
              requireAuth: false,
              NoTabPage: true,
              NoNeedHome: true // 添加该字段，表示不需要home模板
          },
          hidden: true
      },
  ]
})

const router = createRouter()

export function filterAsyncRouter (asyncRouterMap) {
  // console.log(asyncRouterMap)
if(asyncRouterMap){
  //注意这里的 asyncRouterMap 是一个数组
  const accessedRouters = asyncRouterMap.filter(route => {
    // console.log(route)
     route.meta = {
      title:  route.name,
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
     }
    if (route.path && !route.IsButton) {
      if (route.path === '/' || route.path === '-') {//Layout组件特殊处理
        route.component = Layout
      }
      else if (route.type == 1) {
        route.path = "/userTemplate?reportUrl="+route.path+"&reportType=0&t="+Date.now();
        route.component = () => import('@/views//userTemplate.vue');
      } else {
        try {
          route.component = _import(route.path.replace('/:id', ''))
        } catch (e) {
          try {
           
            route.component = () => import('@/views' + route.path.replace('/:id', '') + '.vue');
          

          } catch (error) {
            console.info('%c 当前路由 ' + route.path.replace('/:id', '') + '.vue 不存在，因此如法导入组件，请检查接口数据和组件是否匹配，并重新登录，清空缓存!', "color:red")
          }
        }
      }
    }
    if (route.children && route.children.length && !route.IsButton) {
      route.children = filterAsyncRouter(route.children)
    }
    return true
  })

  return accessedRouters
}
}

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
}


router.$addRoutes = (params) => {
//console.log(params)
if(params){
  var f = item => {

    if (item['children']) {
      item['children'] = item['children'].filter(f);
      return true;
    } else if (item['IsButton']) {
      return item['IsButton'] === false;
    } else {
      return true;
    }

  }

  var params = params.filter(f);
// console.log(params)
  router.addRoutes(params)
}
}

export default router;

